import React from "react";
import MainPage from "./MainPage";
import Product from "./ProductDetails";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function App() {
  return (
    <Router>
      <div>
        <p className="notSupported">This Resolution Is Not Supported.</p>
        <Switch>
          <Route path="/product/:id">
            <Product />
          </Route>
          <Route path="/">
            <MainPage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
