import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faBriefcase,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";

class FeaturesList extends React.Component {
  render() {
    return this.props.Language === "Turkish" ? (
      <div
        className="backgroundblackedcontainer wow bounceInUp animated"
        style={{
          color: "white",
          visibility: "visible",
          animationDuration: "0.5s",
          animationName: "bounceInUp",
        }}
        id="features"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4 box otherBoxes">
              <h4
                className="titleoffeatures WhiteTextLeft wow pulse animated"
                data-wow-offset="50"
                data-wow-delay="0.3s"
                style={{
                  color: "white",
                  visibility: "visible",
                  animationDuration: "0.3s",
                  animationName: "pulse",
                }}
              >
                <FontAwesomeIcon color="white" icon={faUsers} /> Değerlerimiz
              </h4>
              <p className="description WhiteTextLeft">
                Sıkı çalışmak, dürüstlük ve doğru yerde doğru zamanda doğru
                çözümleri bulma ve müşteri odaklı çalışmayı kendimize ilke
                edindik. Türkiye’de teknolojinin en üst seviyesinde yaratıcı
                çözümleri milli olarak geliştirebilmek ve her yönden itibarı
                yüksek bir yapı içinde, ülkemizi bilişim teknolojileri alanında
                bir üretim yeri haline getirmek bizim temel değerlerimizi
                oluşturmaktadır.
              </p>
            </div>

            <div className="col-lg-4 box box-bg Midbox">
              <h4
                className="titleoffeatures WhiteTextLeft wow pulse animated"
                data-wow-offset="50"
                data-wow-delay="0.3s"
                style={{
                  color: "white",
                  visibility: "visible",
                  animationDuration: "0.3s",
                  animationName: "pulse",
                }}
              >
                <FontAwesomeIcon color="white" icon={faUserTie} /> Biz Kimiz
              </h4>
              <p className="description WhiteTextLeft">
                AAES İleri Mühendislik, 2011 yılında kurularak ar-ge
                faaliyetlerine başlamıştır. Havacılık-Uzay, İnşaat, Savunma,
                Enerji, Demiryolları ve Beyaz Eşya sanayi gibi pek çok
                sektörlerindeki müşterilerine; proje/program yönetimi, tasarım
                ve üretim konularında mühendislik hizmetleri vermektedir.
                Tasarım ve Üretim sektöründeki derin teknik bilgi ve
                tecrübelerini Ortadoğu ve Kuzey Afrika ülkelerindeki lider kurum
                ve kuruluşlar ile paylaşmaktadır.
              </p>
            </div>

            <div className="col-lg-4 box otherBoxes">
              <h4
                className="titleoffeatures WhiteTextLeft wow pulse animated"
                data-wow-offset="50"
                data-wow-delay="0.3s"
                style={{
                  color: "white",
                  visibility: "visible",
                  animationDuration: "0.3s",
                  animationName: "pulse",
                }}
              >
                <FontAwesomeIcon color="white" icon={faBriefcase} /> Farkımız
              </h4>
              <p className="description WhiteTextLeft">
                Her şartta sadık kaldığımız değerlerimiz ve tüm çalışanlarımızın
                çok özverili katkısı ile ben değil biz olgusundan yola çıkarak,
                müşterilerimizin taleplerine en hızlı, en uygun ve ileri
                teknoloji içeren çözümler sağlayarak onların sektörlerinde
                büyümelerine destek olmaktayız.
              </p>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div
        className="backgroundblackedcontainer wow bounceInUp animated"
        style={{
          color: "white",
          visibility: "visible",
          animationDuration: "0.5s",
          animationName: "bounceInUp",
        }}
        id="features"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4 box otherBoxes">
              <h4
                className="titleoffeatures WhiteTextLeft wow pulse animated"
                data-wow-offset="50"
                data-wow-delay="0.3s"
                style={{
                  color: "white",
                  visibility: "visible",
                  animationDuration: "0.3s",
                  animationName: "pulse",
                }}
              >
                <FontAwesomeIcon color="white" icon={faUsers} /> Values
              </h4>
              <p className="description WhiteTextLeft">
                We have adopted the principle of hard work, honesty, finding the
                right solutions in the right place at the right time and
                customer-oriented work. Our core values are to develop creative
                solutions at the highest level of technology in Turkey and to
                make our country a production place in the field of information
                technology within a highly reputable structure in every aspect.
              </p>
            </div>

            <div className="col-lg-4 box box-bg Midbox">
              <h4
                className="titleoffeatures WhiteTextLeft wow pulse animated"
                data-wow-offset="50"
                data-wow-delay="0.3s"
                style={{
                  color: "white",
                  visibility: "visible",
                  animationDuration: "0.3s",
                  animationName: "pulse",
                }}
              >
                <FontAwesomeIcon color="white" icon={faUserTie} /> Who We Are
              </h4>
              <p className="description WhiteTextLeft">
                AAES Advanced Engineering was established in 2011 and started
                its R&D activities. It provides engineering services in
                project/program management, design and production to its
                customers in many sectors such as Aerospace, Construction,
                Defence, Energy, Railways and White Goods industry. It shares
                its deep technical knowledge and experience in the Design and
                Production sector with leading institutions and organisations in
                the Middle East and North African countries.
              </p>
            </div>

            <div className="col-lg-4 box otherBoxes">
              <h4
                className="titleoffeatures WhiteTextLeft wow pulse animated"
                data-wow-offset="50"
                data-wow-delay="0.3s"
                style={{
                  color: "white",
                  visibility: "visible",
                  animationDuration: "0.3s",
                  animationName: "pulse",
                }}
              >
                <FontAwesomeIcon color="white" icon={faBriefcase} /> Our
                difference
              </h4>
              <p className="description WhiteTextLeft">
                We support the growth of our customers in their sectors by
                providing the fastest, most appropriate and advanced technology
                solutions to the demands of our customers, based on our values
                that we remain loyal under all circumstances and the very
                devoted contribution of all our employees.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FeaturesList;
