function productListReducer(state = {}, action) {
  switch (action.type) {
    case "getproductliststarted":
      return "loading";
    case "getproductlistsuccess":
      return action.payload;
    case "getproductlistfailed":
      return "failed";
    default:
      return state;
  }
}

export default productListReducer;
