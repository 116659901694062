import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  ProductNavigationBar,
  ProductCarousel,
  ParallaxProduct,
  DocumentsProduct,
} from "../Components";
import Cookies from "js-cookie";

class ProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onTop: true,
      postanimation: false,
      pname: "",
      pPlatform: "",
      pPlatformEn: "",
      pDescriptions: [],
      pDescriptionsEn: [],
      pImages: [],
      pVideos: [],
      pDocuments: [],
    };
  }

  componentDidMount() {
    window.onscroll = () => {
      if (window.pageYOffset === 0) {
        this.setState({ onTop: true });
      } else {
        this.setState({ onTop: false });
      }
    };
    this.getDataFromApi();
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.setState({ Language: Cookies.get("Language") });
  }

  componentWillUnmount() {
    window.onscroll = null;
  }

  async getDataFromApi() {
    await axios
      .get(
        "http://81.214.140.132:9902/product/" + this.props.match.params.id
      )
      .then((res) => {
        if (res.data === "Product Not Found") {
          console.log("You are trying to exploit? lol.");
          this.props.history.push("/");
        } else {
          let pname = "";
          let pPlatform = "";
          let pPlatformEn = "";
          let pDescriptions = [];
          let pDescriptionsEn = [];
          let pImages = [];
          let pVideos = [];
          let pDocuments = [];
          let dataexists = false;
          res.data.forEach((element) => {
            if (
              element[0] !== undefined &&
              Array.isArray(Object.entries(element[0])[1])
            ) {
              if (element.length > 2) {
                element.forEach((elem) => {
                  let temp = [];
                  Object.keys(elem).map((i) => {
                    return temp.push(elem[i]);
                  });
                  temp.reverse();
                  pDescriptionsEn.push([temp[0], temp[1]]);
                  pDescriptions.push([temp[2], temp[3]]);
                  dataexists = true;
                });
              } else {
                element.forEach((elem) => {
                  Object.keys(elem).map((i) => {
                    if (i === "Platorm Name") {
                      return (pPlatform = elem[i]);
                    } else if (i === "Platorm NameEn") {
                      return (pPlatformEn = elem[i]);
                    } else {
                      return elem[i];
                    }
                  });
                });
              }
            } else {
              element.forEach((elem) => {
                Object.keys(elem).map((i) => {
                  if (i === "name") {
                    return (pname = elem[i]);
                  } else if (i === "Image") {
                    dataexists = true;
                    return pImages.push(elem[i]);
                  } else if (i === "Video") {
                    dataexists = true;
                    return pVideos.push(elem[i]);
                  } else if (i === "Document") {
                    dataexists = true;
                    return pDocuments.push(elem[i]);
                  } else {
                    return console.log("enjoy nuke");
                  }
                });
              });
            }
          });
          this.setState({
            pname: pname,
            pPlatform: pPlatform,
            pPlatformEn: pPlatformEn,
            pDescriptions: pDescriptions,
            pDescriptionsEn: pDescriptionsEn,
            pImages: pImages,
            pVideos: pVideos,
            pDocuments: pDocuments,
          });
          if (!dataexists) {
            this.props.history.push("/");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return this.state.Language === "Turkish" ? (
      <div className="App">
        <header>
          <ProductNavigationBar onTop={this.state.onTop} />
        </header>
        {this.state.pImages && this.state.pImages.length ? (
          <ProductCarousel
            productName={this.state.pname}
            productImages={this.state.pImages}
            productVideos={this.state.pVideos}
          />
        ) : (
          ""
        )}
        {this.state.pDescriptions && this.state.pDescriptions.length ? (
          <ParallaxProduct
            productName={this.state.pname}
            platformName={this.state.pPlatform}
            Descriptions={this.state.pDescriptions}
          />
        ) : (
          ""
        )}
        {this.state.pDocuments && this.state.pDocuments.length ? (
          <DocumentsProduct
            Language={this.state.Language}
            productDocuments={this.state.pDocuments}
          />
        ) : (
          ""
        )}
      </div>
    ) : (
      <div className="App">
        <header>
          <ProductNavigationBar onTop={this.state.onTop} />
        </header>
        {this.state.pImages && this.state.pImages.length ? (
          <ProductCarousel
            productName={this.state.pname}
            productImages={this.state.pImages}
            productVideos={this.state.pVideos}
          />
        ) : (
          ""
        )}
        {this.state.pDescriptionsEn && this.state.pDescriptionsEn.length ? (
          <ParallaxProduct
            productName={this.state.pname}
            platformName={this.state.pPlatformEn}
            Descriptions={this.state.pDescriptionsEn}
          />
        ) : (
          ""
        )}
        {this.state.pDocuments && this.state.pDocuments.length ? (
          <DocumentsProduct productDocuments={this.state.pDocuments} />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withRouter(ProductDetails);
