import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Nav, Navbar, Image } from "react-bootstrap";
import Logo from "../../Pictures/logo.png";
import { withRouter } from "react-router-dom";

class ProductNavigationBar extends React.Component {
  render() {
    return (
      <Navbar
        id="navbar"
        collapseOnSelect
        bg="dark"
        variant="dark"
        className="navbarnotontop"
      >
        <Nav
          onClick={() => {
            this.props.history.push("/");
          }}
          className="productNavbarLeftIconBig"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Nav>
        <Nav className="productNavbarIconToCenter">
          <Image
            src={Logo}
            className={
              this.props.onTop ? "imageLogoLeftUp" : "imageLogoLeftUpsmall"
            }
            alt="Website logo"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          />
        </Nav>
      </Navbar>
    );
  }
}

export default withRouter(ProductNavigationBar);
