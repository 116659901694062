import React from "react";
import { Carousel } from "react-bootstrap";

class ImageCarousel extends React.Component {
  render() {
    return this.props.Language === "Turkish" ? (
      <Carousel id="caro">
        <Carousel.Item className="videocontainer">
          <img
            className="imageOfCarousel"
            src={process.env.PUBLIC_URL + "/Carousel/carousel1.jpg"}
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>Mühendislik Yönetimi Danışmanlığı</h3>
            <p>
              Mühendislik danışmanlık hizmetleri ile kurumunuzun misyon ve
              hedefleri ile stratejik uyum içinde düşük efor ile daha fazlasını
              elde etmenize yardımcı olur.
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className="videocontainer">
          <img
            className="imageOfCarousel"
            src={process.env.PUBLIC_URL + "/Carousel/carousel2.jpg"}
            alt="Third slide"
          />

          <Carousel.Caption>
            <h3>Mühendislik</h3>
            <p>
              AAES, kurum ve kuruluşların mühendislik ve operasyonlarına yönelik
              desteği ile kurumun program ve maliyet-etkin faaliyetlerini
              başarılı bir şekilde yerine getirmelerine yönelik misyonlarında
              büyük başarı sağlamaktadır
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className="videocontainer">
          <img
            className="imageOfCarousel"
            src={process.env.PUBLIC_URL + "/Carousel/carousel3.jpg"}
            alt="Third slide"
          />

          <Carousel.Caption>
            <h3>Teknoloji</h3>
            <p>
              Kuruluşlar günümüzün rekabet koşulları içinde iş hedefleri ve
              misyonlarına ulaşmak için ileri teknolojik ürünleri kullanmak
              zorundalar.
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className="videocontainer">
          <img
            className="imageOfCarousel"
            src={process.env.PUBLIC_URL + "/Carousel/carousel4.jpg"}
            alt="Third slide"
          />

          <Carousel.Caption>
            <h3>Tedarik</h3>
            <p>
              Uluslararası ve yerli kaynakları ile en iyi çözümler üzerine
              odaklanan bir takım olarak ürün, parça, komponent temini
              sağlıyoruz.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    ) : (
      <Carousel id="caro">
        <Carousel.Item className="videocontainer">
          <img
            className="imageOfCarousel"
            src={process.env.PUBLIC_URL + "/Carousel/carousel1.jpg"}
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>Engineering Management Consultancy</h3>
            <p>
              Engineering consultancy services help you achieve more with less
              effort in strategic alignment with the mission and goals of your
              company.
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className="videocontainer">
          <img
            className="imageOfCarousel"
            src={process.env.PUBLIC_URL + "/Carousel/carousel2.jpg"}
            alt="Third slide"
          />

          <Carousel.Caption>
            <h3>Engineering</h3>
            <p>
              AAES provides engineering and operations support to organisations
              and institutions, enabling them to achieve success in their
              mission to successfully deliver on their programmes and
              cost-effective activities.
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className="videocontainer">
          <img
            className="imageOfCarousel"
            src={process.env.PUBLIC_URL + "/Carousel/carousel3.jpg"}
            alt="Third slide"
          />

          <Carousel.Caption>
            <h3>Technology</h3>
            <p>
              The enterprises have to use advanced technological products in
              order to achieve their business goals and missions in today's
              competitive conditions.
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className="videocontainer">
          <img
            className="imageOfCarousel"
            src={process.env.PUBLIC_URL + "/Carousel/carousel4.jpg"}
            alt="Third slide"
          />

          <Carousel.Caption>
            <h3>Procurement</h3>
            <p>
              We supply products, parts and components as a team focusing on the
              best solutions with international and local resources.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

export default ImageCarousel;
