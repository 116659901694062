import isLoggedReducer from "./isLogged";
import isLoadingReducer from "./loadingState";
import productListReducer from "./productList";
import { combineReducers } from "redux";

const allreducers = combineReducers({
  isLoggedIn: isLoggedReducer,
  loading: isLoadingReducer,
  product: productListReducer,
});

export default allreducers;
