import React from "react";
import { Carousel } from "react-bootstrap";

class ProductImageCarousel extends React.Component {
  render() {
    return (
      <Carousel id="caro" data-interval="false" indicators={false}>
        {Object.keys(this.props.productVideos).map((i) => {
          return (
            <Carousel.Item className="videocontainer" key={i}>
              <iframe
                className="videoresponsiveiframe"
                title="hi"
                src={this.props.productVideos[i]}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Carousel.Item>
          );
        })}
        {Object.keys(this.props.productImages).map((i) => {
          return (
            <Carousel.Item className="videocontainer" key={i}>
              <img
                className="imageOfCarousel"
                src={process.env.PUBLIC_URL + this.props.productImages[i]}
                alt={"Image Of " + this.props.productName}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    );
  }
}

export default ProductImageCarousel;
