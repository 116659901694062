function isLoadingReducer(state = true, action) {
  switch (action.type) {
    case "ShowLoading":
      return true;
    case "HideLoading":
      return false;
    default:
      return state;
  }
}

export default isLoadingReducer;
