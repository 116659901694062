import React from "react";

class ParallaxProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = { parallaximageclass: "", maindesc: "", descswithheaders: [] };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let parallaximageclass = "";
    if (prevState.parallaximageclass === "" && nextProps.platformName !== "") {
      if (
        nextProps.platformName === "Kara" ||
        nextProps.platformName === "Land"
      ) {
        parallaximageclass = "landimageback";
      } else if (
        nextProps.platformName === "Hava" ||
        nextProps.platformName === "Air"
      ) {
        parallaximageclass = "airimageback";
      } else if (
        nextProps.platformName === "Ekipman" ||
        nextProps.platformName === "Equipment"
      ) {
        parallaximageclass = "equipmentimageback";
      } else {
        parallaximageclass = "supportimageback";
      }
    }
    if (
      prevState.maindesc === "" &&
      (nextProps.Descriptions === undefined ||
        nextProps.Descriptions.length > 0)
    ) {
      let maindesc = "";
      let descswithheaders = [];
      Object.keys(nextProps.Descriptions).map((i) => {
        if (nextProps.Descriptions[i][0] === "main") {
          return (maindesc = nextProps.Descriptions[i][1]);
        } else {
          return descswithheaders.push(nextProps.Descriptions[i]);
        }
      });
      return {
        parallaximageclass: parallaximageclass,
        maindesc: maindesc,
        descswithheaders: descswithheaders,
      };
    }
    if (parallaximageclass !== "") {
      return { parallaximageclass: parallaximageclass };
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="parallaxOfProduct">
        <div
          id="parallaxsection"
          className={"parallax " + this.state.parallaximageclass}
        ></div>
        <div className={"fixcornerofparallax " + this.state.parallaximageclass}>
          <div className={"inside-parallax " + this.state.parallaximageclass}>
            <div
              className="parallax-header wow pulse animated"
              data-wow-offset="50"
              data-wow-delay="0.3s"
              style={{
                color: "white",
                visibility: "visible",
                animationDuration: "0.3s",
                animationName: "pulse",
              }}
            >
              <h3 className="parallax-header-title">
                {this.props.productName}
              </h3>
              <p style={{ whiteSpace: "pre-wrap" }}>{this.state.maindesc}</p>
            </div>
            <div className="Col parallax-column-product">
              {this.state.descswithheaders.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="row wow pulse animated productDescription"
                    data-wow-offset="50"
                    data-wow-delay="0.3s"
                    style={{
                      color: "white",
                      visibility: "visible",
                      animationDuration: "0.3s",
                      animationName: "pulse",
                    }}
                  >
                    <div className="product-col">
                      <h3 className="parallax-header-title">{item[0]}</h3>
                      <p
                        key={i}
                        className="product-col-p"
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {item[1]}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className={"parallax " + this.state.parallaximageclass}> </div>
      </div>
    );
  }
}
export default ParallaxProduct;
