import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faToolbox,
  faPhoneAlt,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";

class ContactSection extends React.Component {
  render() {
    return this.props.Language === "Turkish" ? (
      <div>
        <div className="parallaxcontact randommountainimageback"> </div>
        <div className="contactholder randommountainimageback">
          <div id="contactsection" className="row contact-info">
            <div className="col-md-4 contactnfofox">
              <h2
                className=" wow pulse animated"
                data-wow-offset="50"
                data-wow-delay="0.3s"
                style={{
                  color: "white",
                  visibility: "visible",
                  animationDuration: "0.3s",
                  animationName: "pulse",
                }}
              >
                Merkez <FontAwesomeIcon color="white" icon={faToolbox} />
              </h2>
              <p>İvedik OSB Öz Anadolu San.Sit</p>
              <p>1455. Sk. No: 15, 06374</p>
              <p>İvedik Osb/Yenimahalle/Ankara</p>
            </div>
            <div className="col-md-4 contactnfofox">
              <h2
                className=" wow pulse animated"
                data-wow-offset="50"
                data-wow-delay="0.3s"
                style={{
                  color: "white",
                  visibility: "visible",
                  animationDuration: "0.3s",
                  animationName: "pulse",
                }}
              >
                İletişim <FontAwesomeIcon color="white" icon={faPhoneAlt} />
              </h2>
              <p>Telefon: 0 312 394 82 84</p>
              <p>Faks: 0 312 394 82 85</p>
              <p>E-posta: info@aaes.com.tr</p>
            </div>
            <div className="col-md-4 contactnfofox">
              <h2
                className=" wow pulse animated"
                data-wow-offset="50"
                data-wow-delay="0.3s"
                style={{
                  color: "white",
                  visibility: "visible",
                  animationDuration: "0.3s",
                  animationName: "pulse",
                }}
              >
                Teknofis <FontAwesomeIcon color="white" icon={faBuilding} />
              </h2>
              <p>Teknopark Ankara</p>
              <p>Serhat, 2224. Cd. No:1 C Blok Kat:7, 06378 İvedik</p>
              <p>İvedik Osb/Yenimahalle/Ankara</p>
            </div>
          </div>
        </div>
        <div className="mapsholder randommountainimageback">
          <Row id="mapsection" className="mapsrow">
            <Col className="mapscolumn">
              <iframe
                title="Gmap1"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3056.8798819577273!2d32.732415415710996!3d39.98878958947759!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d349f18e932b93%3A0x244996b8263c903c!2zQUFFUyDEsExFUsSwIE3DnEhFTkTEsFNMxLBL!5e0!3m2!1sen!2str!4v1598691194534!5m2!1sen!2str"
                frameBorder="0"
                aria-hidden="false"
                tabIndex="0"
                className="Gmap"
              ></iframe>
            </Col>
            <Col className="mapscolumn">
              <iframe
                title="Gmap2"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d589.232825285729!2d32.75250606424998!3d39.99589975628875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34a2630ab1a15%3A0x4f6a966ea49cc7de!2zVEVLTk9QQVJLIEFOS0FSQSBURUtOT0xPSsSwIEdFTMSwxZ5UxLBSTUUgQsOWTEdFU8Sw!5e0!3m2!1sen!2str!4v1598690081330!5m2!1sen!2str"
                frameBorder="0"
                aria-hidden="false"
                tabIndex="0"
                className="Gmap"
              ></iframe>
            </Col>
          </Row>
        </div>
        <div className="parallaxcontact randommountainimageback"> </div>
      </div>
    ) : (
      <div>
        <div className="parallaxcontact randommountainimageback"> </div>
        <div className="contactholder randommountainimageback">
          <div id="contactsection" className="row contact-info">
            <div className="col-md-4 contactnfofox">
              <h2
                className=" wow pulse animated"
                data-wow-offset="50"
                data-wow-delay="0.3s"
                style={{
                  color: "white",
                  visibility: "visible",
                  animationDuration: "0.3s",
                  animationName: "pulse",
                }}
              >
                Headquarter <FontAwesomeIcon color="white" icon={faToolbox} />
              </h2>
              <p>İvedik OSB Öz Anadolu San.Sit</p>
              <p>1455. Sk. No: 15, 06374</p>
              <p>İvedik Osb/Yenimahalle/Ankara</p>
            </div>
            <div className="col-md-4 contactnfofox">
              <h2
                className=" wow pulse animated"
                data-wow-offset="50"
                data-wow-delay="0.3s"
                style={{
                  color: "white",
                  visibility: "visible",
                  animationDuration: "0.3s",
                  animationName: "pulse",
                }}
              >
                Contact <FontAwesomeIcon colour="white" icon={faPhoneAlt} />
              </h2>
              <p>Telephone: 0 312 394 82 84</p>
              <p>Fax: 0 312 394 82 85</p>
              <p>Email: info@aaes.com.tr</p>
            </div>
            <div className="col-md-4 contactnfofox">
              <h2
                className=" wow pulse animated"
                data-wow-offset="50"
                data-wow-delay="0.3s"
                style={{
                  color: "white",
                  visibility: "visible",
                  animationDuration: "0.3s",
                  animationName: "pulse",
                }}
              >
                Techoffice <FontAwesomeIcon color="white" icon={faBuilding} />
              </h2>
              <p>Teknopark Ankara</p>
              <p>Serhat, 2224. Cd. No:1 C Blok Kat:7, 06378 İvedik</p>
              <p>İvedik Osb/Yenimahalle/Ankara</p>
            </div>
          </div>
        </div>
        <div className="mapsholder randommountainimageback">
          <Row id="mapsection" className="mapsrow">
            <Col className="mapscolumn">
              <iframe
                title="Gmap1"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3056.8798819577273!2d32.732415415710996!3d39.98878958947759!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d349f18e932b93%3A0x244996b8263c903c!2zQUFFUyDEsExFUsSwIE3DnEhFTkTEsFNMxLBL!5e0!3m2!1sen!2str!4v1598691194534!5m2!1sen!2str"
                frameBorder="0"
                aria-hidden="false"
                tabIndex="0"
                className="Gmap"
              ></iframe>
            </Col>
            <Col className="mapscolumn">
              <iframe
                title="Gmap2"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d589.232825285729!2d32.75250606424998!3d39.99589975628875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34a2630ab1a15%3A0x4f6a966ea49cc7de!2zVEVLTk9QQVJLIEFOS0FSQSBURUtOT0xPSsSwIEdFTMSwxZ5UxLBSTUUgQsOWTEdFU8Sw!5e0!3m2!1sen!2str!4v1598690081330!5m2!1sen!2str"
                frameBorder="0"
                aria-hidden="false"
                tabIndex="0"
                className="Gmap"
              ></iframe>
            </Col>
          </Row>
        </div>
        <div className="parallaxcontact randommountainimageback"> </div>
      </div>
    );
  }
}

export default ContactSection;
