import React from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";

class Products extends React.Component {
  constructor(props) {
    super(props);
    var productcount = 0;
    var namesndesc = [];
    var namesndescEn = [];
    if (this.props.products !== "failed" && this.props.products !== "loading") {
      productcount = Object.keys(this.props.products).length;
      if (productcount > 0) {
        var tmp = [];
        var tmpEn = [];
        Object.keys(this.props.products).forEach((i) => {
          tmp.push([
            this.props.products[i].name,
            this.props.products[i].miniDescription,
            this.props.products[i].id,
          ]);
          if (tmp.length === 4) {
            namesndesc.push(tmp);
            tmp = [];
          }
        });
        if (tmp.length > 0) {
          namesndesc.push(tmp);
          tmp = [];
        }

        Object.keys(this.props.products).forEach((i) => {
          tmpEn.push([
            this.props.products[i].name,
            this.props.products[i].miniDescriptionEn,
            this.props.products[i].id,
          ]);
          if (tmpEn.length === 4) {
            namesndescEn.push(tmpEn);
            tmpEn = [];
          }
        });
        if (tmpEn.length > 0) {
          namesndescEn.push(tmpEn);
          tmpEn = [];
        }
      }
    }
    this.state = {
      productCount: productcount,
      productNamesAndDescriptions: namesndesc,
      productNamesAndDescriptionsEn: namesndescEn,
    };
  }

  render() {
    return this.props.Language === "Turkish" ? (
      <div className="productsSection" id="productssection">
        <h1>
          <FontAwesomeIcon icon={faBars}></FontAwesomeIcon> Ürünlerimiz
        </h1>
        {this.state.productNamesAndDescriptions.map((item, i) => {
          return (
            <Row className="productRow" key={i}>
              {item.map((item2, j) => {
                return (
                  <Col
                    key={j}
                    onClick={() => {
                      this.props.history.push("/product/" + item2[2]);
                    }}
                  >
                    <h2
                      className=" wow pulse animated"
                      data-wow-offset="50"
                      data-wow-delay="0.3s"
                      style={{
                        color: "white",
                        visibility: "visible",
                        animationDuration: "1s",
                        animationName: "pulse",
                      }}
                    >
                      {item2[0]}
                    </h2>
                    <p>{item2[1]}</p>
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </div>
    ) : (
      <div className="productsSection" id="productssection">
        <h1>
          <FontAwesomeIcon icon={faBars}></FontAwesomeIcon> Products
        </h1>
        {this.state.productNamesAndDescriptionsEn.map((item, i) => {
          return (
            <Row className="productRow" key={i}>
              {item.map((item2, j) => {
                return (
                  <Col
                    key={j}
                    onClick={() => {
                      this.props.history.push("/product/" + item2[2]);
                    }}
                  >
                    <h2
                      className=" wow pulse animated"
                      data-wow-offset="50"
                      data-wow-delay="0.3s"
                      style={{
                        color: "white",
                        visibility: "visible",
                        animationDuration: "1s",
                        animationName: "pulse",
                      }}
                    >
                      {item2[0]}
                    </h2>
                    <p>{item2[1]}</p>
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </div>
    );
  }
}

export default withRouter(Products);
