import axios from "axios";

export const getProductList = () => {
  return (dispatch) => {
    dispatch(getProductListStarted());
    axios
      .get("http://81.214.140.132:9902/getProducts")
      .then((res) => {
        dispatch(getProductListSuccess(res.data));
        setTimeout(() => {
          dispatch(hideLoading());
        }, 300);
      })
      .catch((err) => {
        console.log(err);
        dispatch(getProductListFailure());
        setTimeout(() => {
          dispatch(getProductList());
        }, 1500);
      });
  };
};

const getProductListSuccess = (fetched) => ({
  type: "getproductlistsuccess",
  payload: {
    ...fetched,
  },
});

const getProductListStarted = () => ({
  type: "getproductliststarted",
});

const getProductListFailure = () => ({
  type: "getproductlistfailed",
  payload: {},
});

export const showLoading = () => ({
  type: "ShowLoading",
});

export const hideLoading = () => ({
  type: "HideLoading",
});

export const signIn = () => ({
  type: "Signin",
});

export const signOut = () => ({
  type: "Signout",
});
