function isLoggedReducer(state = false, action) {
  switch (action.type) {
    case "Signin":
      return true;
    case "Signout":
      return false;
    default:
      return state;
  }
}
export default isLoggedReducer;
