import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faMedal,
  faTachometerAlt,
} from "@fortawesome/free-solid-svg-icons";

class ParallaxSections extends React.Component {
  render() {
    return this.props.Language === "Turkish" ? (
      <div>
        <div className="parallax worldmapimageback"></div>
        <div
          id="parallaxsection"
          className="fixcornerofparallax worldmapimageback"
        >
          <div className="inside-parallax worldmapimageback">
            <div className="parallax-header">
              <h3
                className="parallax-header-title wow pulse animated"
                data-wow-offset="50"
                data-wow-delay="0.3s"
                style={{
                  color: "white",
                  visibility: "visible",
                  animationDuration: "1s",
                  animationName: "pulse",
                }}
              >
                Hakkımızda
              </h3>
              <p>
                Yazılım ve Bilgi Teknolojileri alanında çok uzun yıllar hizmet
                veren ekibimiz, ulusal ve uluslararası birçok büyük projenin
                analizini yapmış, tasarlamış, yazılımını gerçekleştirmiş ve
                uygulamaya almıştır.
              </p>
            </div>
            <div className="row">
              <div
                className="col-md-4 wow bounceInUp animated"
                style={{
                  color: "white",
                  visibility: "visible",
                  animationDuration: "0.9s",
                  animationName: "bounceInUp",
                }}
              >
                <div className="about-col">
                  <div className="aboutColumn-image">
                    <img
                      src={process.env.PUBLIC_URL + "/images/about-mission.jpg"}
                      alt=""
                      className="img-fluid"
                    />
                    <div className="aboutColumn-icon">
                      <FontAwesomeIcon
                        className="aboutColumn-i"
                        color="white"
                        icon={faEye}
                      />
                    </div>
                  </div>
                  <h3
                    className="paralaxcolumntitle wow pulse animated"
                    data-wow-offset="50"
                    data-wow-delay="0.3s"
                    style={{
                      color: "white",
                      visibility: "visible",
                      animationDuration: "1s",
                      animationName: "pulse",
                    }}
                  >
                    Vizyonumuz
                  </h3>
                  <p>
                    Güven tesis ederek amaçladığımız başarıyı pekiştirmek ve
                    beklentilerin ötesinde hizmet tedariki sunmak için; Ana iş
                    kollarında yoğunlaşmak ve ileri kalite teknikleri kullanarak
                    maliyeti azaltma ve zaman planlarına uyum sağlamak. Bölgenin
                    en güvenilir bir mühendislik firması olmak.
                  </p>
                </div>
              </div>
              <div
                className="col-md-4 wow bounceInUp animated"
                style={{
                  color: "white",
                  visibility: "visible",
                  animationDuration: "1.3s",
                  animationName: "bounceInUp",
                }}
              >
                <div className="about-col">
                  <div className="aboutColumn-image">
                    <img
                      src={process.env.PUBLIC_URL + "/images/about-plan.jpg"}
                      alt=""
                      className="img-fluid"
                    />
                    <div className="aboutColumn-icon">
                      <FontAwesomeIcon
                        className="aboutColumn-i"
                        color="white"
                        icon={faMedal}
                      />
                    </div>
                  </div>
                  <h3
                    className="paralaxcolumntitle wow pulse animated"
                    data-wow-offset="50"
                    data-wow-delay="0.3s"
                    style={{
                      color: "white",
                      visibility: "visible",
                      animationDuration: "1s",
                      animationName: "pulse",
                    }}
                  >
                    Kazanımlarımız
                  </h3>
                  <p>
                    Güçlü ekibinin bilgi ve tecrübesi ile yaptığı projelerdeki
                    uygulama ve uygulama sonrası memnuniyet, yazılım evimize
                    tercih edilen marka özelliği kazandırmıştır. Sahip olduğumuz
                    partnerlik anlayışı uygulama öncesi ve sonrasındaki müşteri
                    memnuniyet kazanımları, proje ortaklarımızın güvenini daha
                    da perçinlemiştir.
                  </p>
                </div>
              </div>
              <div
                className="col-md-4 wow bounceInUp animated"
                style={{
                  color: "white",
                  visibility: "visible",
                  animationDuration: "0.9s",
                  animationName: "bounceInUp",
                }}
              >
                <div className="about-col">
                  <div className="aboutColumn-image">
                    <img
                      src={process.env.PUBLIC_URL + "/images/about-vision.jpg"}
                      alt=""
                      className="img-fluid"
                    />
                    <div className="aboutColumn-icon">
                      <FontAwesomeIcon
                        className="aboutColumn-i"
                        color="white"
                        icon={faTachometerAlt}
                      />
                    </div>
                  </div>
                  <h3 className="paralaxcolumntitle">Misyonumuz</h3>
                  <p>
                    Müşterilerimiz ve çalışanlarımız için değer üretmeye dönük
                    ürün ve sistemler yaratmak ve tüm ileri mühendislik ve
                    kalite tekniklerini kullanarak bunları etkin bir şekilde
                    hayata geçirilmesini sağlamaktır.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="parallax worldmapimageback"> </div>
      </div>
    ) : (
      <div>
        <div className="parallax worldmapimageback"></div>
        <div
          id="parallaxsection"
          className="fixcornerofparallax worldmapimageback"
        >
          <div className="inside-parallax worldmapimageback">
            <div className="parallax-header">
              <h3
                className="parallax-header-title wow pulse animated"
                data-wow-offset="50"
                data-wow-delay="0.3s"
                style={{
                  color: "white",
                  visibility: "visible",
                  animationDuration: "1s",
                  animationName: "pulse",
                }}
              >
                About Us
              </h3>
              <p>
                Our team, which has been serving in the field of Software and
                Information Technologies for many years, has made analysis,
                designed, developed and implemented many large national and
                international projects.
              </p>
            </div>
            <div className="row">
              <div
                className="col-md-4 wow bounceInUp animated"
                style={{
                  color: "white",
                  visibility: "visible",
                  animationDuration: "0.9s",
                  animationName: "bounceInUp",
                }}
              >
                <div className="about-col">
                  <div className="aboutColumn-image">
                    <img
                      src={process.env.PUBLIC_URL + "/images/about-mission.jpg"}
                      alt=""
                      className="img-fluid"
                    />
                    <div className="aboutColumn-icon">
                      <FontAwesomeIcon
                        className="aboutColumn-i"
                        color="white"
                        icon={faEye}
                      />
                    </div>
                  </div>
                  <h3
                    className="paralaxcolumntitle wow pulse animated"
                    data-wow-offset="50"
                    data-wow-delay="0.3s"
                    style={{
                      color: "white",
                      visibility: "visible",
                      animationDuration: "1s",
                      animationName: "pulse",
                    }}
                  >
                    Our Vision
                  </h3>
                  <p>
                    To consolidate the success we aim by establishing trust and
                    to provide service supply beyond expectations; To
                    concentrate on the main business lines and to reduce costs
                    by using advanced quality techniques and to comply with time
                    plans. To be the most reliable engineering company in the
                    region.
                  </p>
                </div>
              </div>
              <div
                className="col-md-4 wow bounceInUp animated"
                style={{
                  color: "white",
                  visibility: "visible",
                  animationDuration: "1.3s",
                  animationName: "bounceInUp",
                }}
              >
                <div className="about-col">
                  <div className="aboutColumn-image">
                    <img
                      src={process.env.PUBLIC_URL + "/images/about-plan.jpg"}
                      alt=""
                      className="img-fluid"
                    />
                    <div className="aboutColumn-icon">
                      <FontAwesomeIcon
                        className="aboutColumn-i"
                        color="white"
                        icon={faMedal}
                      />
                    </div>
                  </div>
                  <h3
                    className="paralaxcolumntitle wow pulse animated"
                    data-wow-offset="50"
                    data-wow-delay="0.3s"
                    style={{
                      color: "white",
                      visibility: "visible",
                      animationDuration: "1s",
                      animationName: "pulse",
                    }}
                  >
                    Our gains
                  </h3>
                  <p>
                    With the knowledge and experience of its strong team, the
                    application and post-application satisfaction in its
                    projects has made our software house a preferred brand. Our
                    understanding of partnership, customer satisfaction gains
                    before and after the application, has further reinforced the
                    trust of our project partners.
                  </p>
                </div>
              </div>
              <div
                className="col-md-4 wow bounceInUp animated"
                style={{
                  color: "white",
                  visibility: "visible",
                  animationDuration: "0.9s",
                  animationName: "bounceInUp",
                }}
              >
                <div className="about-col">
                  <div className="aboutColumn-image">
                    <img
                      src={process.env.PUBLIC_URL + "/images/about-vision.jpg"}
                      alt=""
                      className="img-fluid"
                    />
                    <div className="aboutColumn-icon">
                      <FontAwesomeIcon
                        className="aboutColumn-i"
                        color="white"
                        icon={faTachometerAlt}
                      />
                    </div>
                  </div>
                  <h3 className="paralaxcolumntitle">Our Mission</h3>
                  <p>
                    Our mission is to create products and systems that create
                    value for our customers and employees and to ensure their
                    effective implementation by using all advanced engineering
                    and quality techniques.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="parallax worldmapimageback"> </div>
      </div>
    );
  }
}
export default ParallaxSections;
