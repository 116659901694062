import {
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileVideo,
  faFileWord,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "react-bootstrap";

class DocumentsProduct extends React.Component {
  fileExtention(filename) {
    var regexFileExtension = /(\w+)$/;
    var result = regexFileExtension.exec(filename)[1];
    if (result === "pdf") {
      return faFilePdf;
    } else if (result === "zip" || result === "rar") {
      return faFileArchive;
    } else if (result === "mp3" || result === "wav") {
      return faFileAudio;
    } else if (
      result === "cpp" ||
      result === "py" ||
      result === "h" ||
      result === "c" ||
      result === "js" ||
      result === "ts" ||
      result === "jssx"
    ) {
      return faFileCode;
    } else if (result === "csv") {
      return faFileCsv;
    } else if (result === "odf" || result === "xls") {
      return faFileExcel;
    } else if (result === "png" || result === "jpg") {
      return faFileImage;
    } else if (result === "mp4" || result === "avi") {
      return faFileVideo;
    } else if (result === "doc" || result === "docx") {
      return faFileWord;
    } else {
      return faFileAlt;
    }
  }
  render() {
    var regex = /^.*\/(.*)\.?(.*)$/;
    return this.props.Language === "Turkish" ? (
      <div className="productDocsSector">
        <div className="product-col">
          <h1 className="doc-header-title">Destek</h1>
        </div>
        <div>
          <Row className="documentCol">
            {Object.keys(this.props.productDocuments).map((i) => {
              return (
                <Col>
                  <div className="downloadableDocument">
                    <a href={this.props.productDocuments[i]} key={i}>
                      <FontAwesomeIcon
                        icon={this.fileExtention(
                          this.props.productDocuments[i]
                        )}
                        style={{
                          color: "white",
                          backgroundColor: "black",
                          fontSize: "10rem",
                          margin: "auto",
                        }}
                      ></FontAwesomeIcon>
                      <p>{regex.exec(this.props.productDocuments[i])[1]}</p>
                    </a>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    ) : (
      <div className="productDocsSector">
        <div className="product-col">
          <h1 className="doc-header-title">Support</h1>
        </div>
        <div>
          <Row className="documentCol">
            {Object.keys(this.props.productDocuments).map((i) => {
              return (
                <Col>
                  <div className="downloadableDocument">
                    <a href={this.props.productDocuments[i]} key={i}>
                      <FontAwesomeIcon
                        icon={this.fileExtention(
                          this.props.productDocuments[i]
                        )}
                        style={{
                          color: "white",
                          backgroundColor: "black",
                          fontSize: "10rem",
                          margin: "auto",
                        }}
                      ></FontAwesomeIcon>
                      <p>{regex.exec(this.props.productDocuments[i])[1]}</p>
                    </a>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    );
  }
}

export default DocumentsProduct;
