import React from "react";
import { Nav, Navbar, Image } from "react-bootstrap";
import Logo from "../../Pictures/logo.png";

class SiteNavigation extends React.Component {
  render() {
    return this.props.Language === "Turkish" ? (
      <Navbar
        id="navbar"
        collapseOnSelect
        fixed="top"
        expand="lg"
        bg="dark"
        variant="dark"
        className={
          this.props.onTop ? "navbar navbarontop" : "navbar navbarnotontop"
        }
      >
        <Navbar.Brand>
          <Image
            src={Logo}
            className={
              this.props.onTop ? "imageLogoLeftUp" : "imageLogoLeftUpsmall"
            }
            alt="Website logo"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link
              id="whoweareLink"
              className="Nav-Link"
              onClick={() => {
                this.props.scrolltoelem(document.getElementById("features"));
              }}
            >
              Biz Kimiz
            </Nav.Link>
            <Nav.Link
              id="aboutusLink"
              className="Nav-Link"
              onClick={() => {
                this.props.scrolltoelem(
                  document.getElementById("parallaxsection")
                );
              }}
            >
              Hakkımızda
            </Nav.Link>
            <Nav.Link
              id="productsLink"
              className="Nav-Link"
              onClick={() => {
                this.props.scrolltoelem(
                  document.getElementById("productssection")
                );
              }}
            >
              Ürünlerimiz
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link
              id="contactLink"
              className="Nav-Link"
              onClick={() => {
                this.props.SwitchLanguageOfWebsite();
              }}
            >
              English
            </Nav.Link>
            <Nav.Link
              id="contactLink"
              className="Nav-Link"
              onClick={() => {
                this.props.scrolltoelem(
                  document.getElementById("contactsection")
                );
              }}
            >
              İletişim
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    ) : (
      <Navbar
        id="navbar"
        collapseOnSelect
        fixed="top"
        expand="lg"
        bg="dark"
        variant="dark"
        className={
          this.props.onTop ? "navbar navbarontop" : "navbar navbarnotontop"
        }
      >
        <Navbar.Brand>
          <Image
            src={Logo}
            className={
              this.props.onTop ? "imageLogoLeftUp" : "imageLogoLeftUpsmall"
            }
            alt="Website logo"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link
              id="whoweareLink"
              className="Nav-Link"
              onClick={() => {
                this.props.scrolltoelem(document.getElementById("features"));
              }}
            >
              Who we are
            </Nav.Link>
            <Nav.Link
              id="aboutusLink"
              className="Nav-Link"
              onClick={() => {
                this.props.scrolltoelem(
                  document.getElementById("parallaxsection")
                );
              }}
            >
              About us
            </Nav.Link>
            <Nav.Link
              id="productsLink"
              className="Nav-Link"
              onClick={() => {
                this.props.scrolltoelem(
                  document.getElementById("productssection")
                );
              }}
            >
              Products
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link
              id="contactLink"
              className="Nav-Link"
              onClick={() => {
                this.props.SwitchLanguageOfWebsite();
              }}
            >
              Turkish
            </Nav.Link>
            <Nav.Link
              id="contactLink"
              className="Nav-Link"
              onClick={() => {
                this.props.scrolltoelem(
                  document.getElementById("contactsection")
                );
              }}
            >
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default SiteNavigation;
