import React from "react";
import Cookies from "js-cookie";
import "bootstrap/dist/css/bootstrap.min.css";
import "../StyleSheets/App.css";
import "holderjs";
import { connect } from "react-redux";
import {
  hideLoading,
  showLoading,
  signIn,
  signOut,
  getProductList,
} from "../Actions";

import {
  ContactSection,
  FeaturesList,
  ImageCarousel,
  LoadingCss,
  ParallaxSections,
  Products,
  SiteNavigation,
} from "../Components";

class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { onTop: true, postanimation: false, Language: "Turkish" };
  }

  componentDidMount() {
    this.props.getProductList();
    this.setState({ Language: Cookies.get("Language") });
    window.onscroll = () => {
      if (this.state.postanimation) {
        if (window.pageYOffset === 0) {
          this.whichNavlinkActive(0);
        } else {
          var map = document
            .getElementById("mapsection")
            .getBoundingClientRect().top;
          var fea = document
            .getElementById("features")
            .getBoundingClientRect().top;
          var par = document
            .getElementById("parallaxsection")
            .getBoundingClientRect().top;
          var cont = document
            .getElementById("contactsection")
            .getBoundingClientRect().top;
          var pro = document
            .getElementById("productssection")
            .getBoundingClientRect().top;
          var navbarheader = document.getElementById("navbar").clientHeight;
          if (this.detectScrollAtBottom()) {
            this.whichNavlinkActive(4);
          } else if (map - navbarheader < 3) {
            this.whichNavlinkActive(4);
          } else if (cont - navbarheader < 3) {
            this.whichNavlinkActive(4);
          } else if (pro - navbarheader < 3) {
            this.whichNavlinkActive(3);
          } else if (par - navbarheader < 3) {
            this.whichNavlinkActive(2);
          } else if (fea - navbarheader < 3) {
            this.whichNavlinkActive(1);
          } else {
            this.whichNavlinkActive(0);
          }
        }
      }
    };
  }

  SwitchLanguageOfWebsite() {
    if (this.state.Language === "Turkish") {
      this.setState({ Language: "English" });
      Cookies.set("Language", "English");
    } else {
      this.setState({ Language: "Turkish" });
      Cookies.set("Language", "Turkish");
    }
  }

  componentWillUnmount() {
    window.onscroll = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.products !== this.state.products &&
      nextProps.products !== "loading" &&
      nextProps.products !== "failed"
    ) {
      setTimeout(() => {
        this.setState({ postanimation: true });
      }, 500);
    }
  }

  whichNavlinkActive(index) {
    if (index === 0) {
      this.setState({ onTop: true });
      this.setNavlinkDisabled("whoweareLink");
      this.setNavlinkDisabled("aboutusLink");
      this.setNavlinkDisabled("productsLink");
      this.setNavlinkDisabled("contactLink");
    } else if (index === 1) {
      this.setState({ onTop: false });
      this.setNavlinkActive("whoweareLink");
      this.setNavlinkDisabled("aboutusLink");
      this.setNavlinkDisabled("productsLink");
      this.setNavlinkDisabled("contactLink");
    } else if (index === 2) {
      this.setState({ onTop: false });
      this.setNavlinkDisabled("whoweareLink");
      this.setNavlinkActive("aboutusLink");
      this.setNavlinkDisabled("productsLink");
      this.setNavlinkDisabled("contactLink");
    } else if (index === 3) {
      this.setState({ onTop: false });
      this.setNavlinkDisabled("whoweareLink");
      this.setNavlinkDisabled("aboutusLink");
      this.setNavlinkActive("productsLink");
      this.setNavlinkDisabled("contactLink");
    } else if (index === 4) {
      this.setState({ onTop: false });
      this.setNavlinkDisabled("whoweareLink");
      this.setNavlinkDisabled("aboutusLink");
      this.setNavlinkDisabled("productsLink");
      this.setNavlinkActive("contactLink");
    } else {
      this.setState({ onTop: false });
      this.setNavlinkDisabled("whoweareLink");
      this.setNavlinkDisabled("aboutusLink");
      this.setNavlinkDisabled("productsLink");
      this.setNavlinkDisabled("contactLink");
    }
  }

  setNavlinkActive(idName) {
    document
      .getElementById(idName)
      .setAttribute("style", "color: rgba(255,255,255,1);");
  }
  setNavlinkDisabled(idName) {
    document
      .getElementById(idName)
      .setAttribute("style", "color: rgba(255,255,255,.50);");
  }

  detectScrollAtBottom() {
    const windowHeight = window.innerHeight
      ? window.innerHeight
      : document.documentElement.offsetHeight;
    const { body } = document;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = Math.round(windowHeight + window.pageYOffset);
    const difference = docHeight - windowBottom;
    const additional = difference >= 1 && difference <= 3 ? difference : 0;
    return windowBottom + additional >= docHeight;
  }

  async scrolltoelem(elem) {
    if (elem === null) {
      return;
    }
    var navbarheader = document.getElementById("navbar");
    if (window.pageYOffset === 0) {
      setTimeout(() => {
        window.scrollTo({
          top:
            elem.getBoundingClientRect().top +
            window.pageYOffset -
            navbarheader.clientHeight,
          behavior: "smooth",
        });
      }, 1000);
    }
    window.scrollTo({
      top:
        elem.getBoundingClientRect().top +
        window.pageYOffset -
        navbarheader.clientHeight,
      behavior: "smooth",
    });
  }

  render() {
    return (
      <div className="App">
        <header
          className={!this.props.loading ? "App-Header" : "App-Header-Hidden"}
        >
          <SiteNavigation
            onTop={this.state.onTop}
            scrolltoelem={this.scrolltoelem}
            Language={this.state.Language}
            SwitchLanguageOfWebsite={() => {
              this.SwitchLanguageOfWebsite();
            }}
          />
        </header>

        {!this.state.postanimation && (
          <LoadingCss loading={this.props.loading} />
        )}
        {this.state.postanimation && (
          <ImageCarousel Language={this.state.Language} />
        )}
        {this.state.postanimation && (
          <FeaturesList Language={this.state.Language} />
        )}
        {this.state.postanimation && (
          <ParallaxSections Language={this.state.Language} />
        )}
        {this.state.postanimation && (
          <Products
            Language={this.state.Language}
            products={this.props.products}
          />
        )}
        {this.state.postanimation && (
          <ContactSection Language={this.state.Language} />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.loading,
    isLoggedIn: state.isLoggedIn,
    products: state.product,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    hideLoading: () => dispatch(hideLoading()),
    showLoading: () => dispatch(showLoading()),
    signIn: () => dispatch(signIn()),
    signOut: () => dispatch(signOut()),
    getProductList: () => dispatch(getProductList()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
