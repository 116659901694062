import React from "react";

class LoadingCss extends React.Component {
  render() {
    return (
      <div
        className={
          this.props.loading ? "Loading-Holder" : "Loading-Holder-Hidden"
        }
      >
        <div
          className={this.props.loading ? "lds-ripple" : "lds-ripple-Hidden"}
        >
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}

export default LoadingCss;
